import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
  })

  export class UploaderService {
  private nhsApiUrl: string = environment.nhsApiUrl;

  constructor(private http: HttpClient, public auth: AuthService) {}
    public upload(endpoint: string, file: any, uploaderId?: any): Observable<any> {
    let params: HttpParams = new HttpParams();
    const formData = new FormData();
    formData.append('attendance_file', file)
    if(uploaderId){
      params = params.set('eventId', uploaderId.toString())
    }  
    return this.http.post(
      endpoint,formData, 
      {
        headers: new HttpHeaders()
          .set('Authorization', this.auth.token())
          .set('enctype', 'multipart/form-data'),
          params: params,
        responseType: 'text'
      },
    );

  }

  public staticDocumentUpload(endpoint: string, file: any, projectId?, year?): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params
    .set('projectId', projectId.toString())
    .set('year', year.toString());
    const formData = new FormData();
    file.forEach(files => formData.append('files', files))
    return this.http.post(
      endpoint,formData, 
      {
        headers: new HttpHeaders()
          .set('Authorization', this.auth.token())
          .set('enctype', 'multipart/form-data'),
          params: params,
        responseType: 'text'
      },
    );
  }

  public dynamicDocumentUpload(endpoint: string, file: any, body: any, projectId?, year?): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params
    .set('projectId', projectId.toString())
    .set('year', year.toString());
    const formData = new FormData();
    file.forEach(files => formData.append('files', files))
    formData.append('displaySequence', '1');
    formData.append('documentCategoryId', body.documentCategoryId);
    formData.append('documentName', body.documentName);
    formData.append('isVisible', body.isVisible);
    return this.http.post(
      endpoint,formData,
      {
        headers: new HttpHeaders() 
          .set('Authorization', this.auth.token())
          .set('enctype', 'multipart/form-data'),
          params: params,
        responseType: 'text'
      },
    );
  }

  public editDynamicDocument(projectId, documentId, body): Observable<any> {
    return this.http.patch(this.nhsApiUrl + '/projects/' + documentId + '/modifyDynamicDocuments', body,{
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams()
        .set('id', projectId.toString())
        .set('documentId', documentId.toString())
      });
    }
}